//const HTTP_URL = "http://localhost:5003/api";

// const HTTP_URL = "https://thaoacademy.com:5003/api";
const HTTP_URL = "https://test.thaoacademy.com:6001/api";


export default async function request(url: string, payload: any = null): Promise<any|null>{
  try {
    const res = await fetch(HTTP_URL + url, payload);
    const ret = await res.json();
    return ret;
  } catch (error) {
    return null;
  }
}